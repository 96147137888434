import * as React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import styled from 'styled-components';
import { media } from 'src/styles';
import { htmlSerializer } from 'src/utils';
import { Video, WithoutMargin } from 'src/components/common';
import { ProcessBrandFragment } from 'graphql-types';

type Props = ProcessBrandFragment & {
  className?: string;
  id: string;
};

export const fragment = graphql`
  fragment ProcessBrand on PRISMIC_Brand {
    _meta {
      uid
    }
    process {
      process_video_id
      process_title
      process_content
    }
  }
`;

export const Brand: React.FC<Props> = ({
  _meta: { uid: brandName },
  className,
  id,
  process,
}: Props) => (
  <div className={className || ''} id={id}>
    {process && (
      <Processes
        className={`${process.length > 1 ? 'has-count' : ''} ${brandName}`}
      >
        {process.map(
          ({ process_video_id, process_title, process_content }, index) => (
            <ProcessItem key={`process-${brandName}-${index}`}>
              {process_video_id && (
                <WithoutMargin>
                  <Video videoId={process_video_id} hasController={true} />
                </WithoutMargin>
              )}
              {process_title && (
                <ProcessTitle className={process.length > 1 ? 'has-count' : ''}>
                  {RichText.asText(process_title)}
                </ProcessTitle>
              )}
              {process_content && (
                <ProcessContent>
                  <RichText
                    render={process_content}
                    htmlSerializer={htmlSerializer}
                  />
                </ProcessContent>
              )}
            </ProcessItem>
          )
        )}
      </Processes>
    )}
  </div>
);

const ProcessItem = styled.section`
  & + & {
    margin-top: ${({ theme }) => theme.structure.margin.smallWithText}px;
  }
`;

const ProcessTitle = styled.h3`
  font-size: ${({ theme }) => theme.font.size.xlarge};
  line-height: 1.2;
  margin-top: ${({ theme }) => theme.structure.margin.xSmallWithText}px;
  &.has-count {
    &::before {
      counter-increment: process_number 1;
      content: counter(process_number) '. ';
    }
  }
`;

const ProcessContent = styled.div`
  font-size: ${({ theme }) => theme.font.size.normal};
  margin-top: ${({ theme }) => theme.structure.margin.xSmallWithLargeText}px;
  line-height: ${({ theme }) => theme.lineHeight.ja};
`;

const Processes = styled.div`
  &.has-count {
    counter-reset: process_number 0;
  }
  &.build {
    color: ${({ theme }) => theme.colors.build};
  }
  &.birth {
    color: ${({ theme }) => theme.colors.birth};
  }
  &.seed {
    color: ${({ theme }) => theme.colors.seed};
  }
  ${media.lessThanIpadHorizontal`
    padding-left: ${({ theme }) => theme.structure.siteMargin.vertical.sp}px;
    padding-right: ${({ theme }) => theme.structure.siteMargin.vertical.sp}px;
    padding-bottom: ${({ theme }) => theme.headerHeight.sp}px;
  `}
`;

export default Brand;
