import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { useSlug, useTranslationLink } from 'src/utils';
import { SEO } from 'src/components/common';
import { Content } from 'src/components/process';
import { GetProcessQuery } from 'graphql-types';
import { DefaultPageContextProps } from 'custom-types';

type Props = PageProps<GetProcessQuery, DefaultPageContextProps>;

export const query = graphql`
  query GetProcess($lang: String!) {
    site {
      siteMetadata {
        titleCustom {
          ja {
            process
          }
          en {
            process
          }
        }
        description {
          ja {
            process
          }
          en {
            process
          }
        }
      }
    }
    prismic {
      ...ProcessContent
    }
  }
`;

type UseSEOProps = Pick<DefaultPageContextProps, 'langKey'> & {
  site: GetProcessQuery['site'];
};
function useSEO({ site, langKey }: UseSEOProps) {
  return React.useMemo(() => {
    const title =
      langKey === 'ja'
        ? site?.siteMetadata?.titleCustom?.ja?.process
        : site?.siteMetadata?.titleCustom?.en?.process;
    const description =
      langKey === 'ja'
        ? site?.siteMetadata?.description?.ja?.process
        : site?.siteMetadata?.description?.en?.process;
    return {
      title,
      description,
    };
  }, [site, langKey]);
}

const Process: React.FC<Props> = ({ data: { site, prismic } }: Props) => {
  const { slug, langKey } = useSlug();
  const seo = useSEO({ site, langKey });
  useTranslationLink({ slug, langKey, uid: 'process', type: 'process' });
  return (
    <>
      <SEO {...seo} />
      <Content {...prismic} />
    </>
  );
};

export default Process;
