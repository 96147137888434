import * as React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import styled from 'styled-components';
import { media } from 'src/styles';
import {
  ScreenReaderText,
  BrandLogo,
  WithoutMargin,
  Video,
} from 'src/components/common';
import { Button } from 'src/components/common/atoms';
import DiagonalLine from 'src/images/diagonal-line.inline.svg';
import { ProcessBrandListItemFragment } from 'graphql-types';

type Props = ProcessBrandListItemFragment & {
  className?: string;
  index: number;
  onClick: (index: number) => void;
  targetId: string;
};

export const fragment = graphql`
  fragment ProcessBrandListItem on PRISMIC_Brand {
    _meta {
      uid
    }
    title
    logo_image
    logo_imageSharp {
      publicURL
    }
    process_main_video_id
  }
`;

export const BrandListItem: React.FC<Props> = ({
  _meta,
  className,
  index,
  onClick,
  targetId,
  title,
  logo_imageSharp,
  process_main_video_id,
}: Props) => {
  const { uid: brandName } = _meta;
  const onClickSelf = React.useCallback<
    React.MouseEventHandler<HTMLButtonElement>
  >(() => {
    onClick(index);
  }, [onClick, index]);

  return (
    <Wrapper className={`${className ?? ''} ${brandName}`}>
      <StyledWithoutMargin>
        <StyledButton
          onClick={onClickSelf}
          aria-controls={targetId}
          aria-label={title ? 'Select ${title} brand' : ''}
        >
          <header>
            {title && (
              <h2>
                <ScreenReaderText>{RichText.asText(title)}</ScreenReaderText>
              </h2>
            )}
            {logo_imageSharp?.publicURL && (
              <BrandLogo
                src={logo_imageSharp.publicURL}
                alt={title ? `RichText.asText(title) logo` : ''}
              />
            )}
          </header>
          <HoverLine>
            <DiagonalLine />
          </HoverLine>
        </StyledButton>
        {process_main_video_id && (
          <Content>
            <Video videoId={process_main_video_id} hasController={true} />
          </Content>
        )}
      </StyledWithoutMargin>
    </Wrapper>
  );
};

const HoverLine = styled.div`
  display: none;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  svg {
    width: 100%;
    height: 100%;
  }
`;

const StyledButton = styled(Button)`
  border: none;
  text-align: left;
  width: 100%;
  /* pointer-events: initial; */
  padding-left: ${({ theme }) => theme.structure.siteMargin.vertical.sp}px;
  padding-right: ${({ theme }) => theme.structure.siteMargin.vertical.sp}px;
  &.current,
  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
    outline: none;
  }
  &:hover {
    ${HoverLine} {
      display: block;
    }
  }
  ${media.ipadHorizontalOrMore`
    padding-left: ${({ theme }) => theme.structure.siteMargin.vertical.pc}px;
    padding-right: ${({ theme }) => theme.structure.siteMargin.vertical.pc}px;
  `}
`;

const Content = styled.div`
  margin-top: ${({ theme }) => theme.structure.margin.xSmall}px;
`;

const StyledWithoutMargin = styled(WithoutMargin)`
  padding-top: ${({ theme }) => theme.structure.margin.xSmall}px;
  position: relative;
  /* pointer-events: none; */
  /* &:hover {
    ${HoverLine} {
      display: block;
    }
  } */
`;

const Wrapper = styled.article`
  &.build {
    color: ${({ theme }) => theme.colors.build};
  }
  &.birth {
    color: ${({ theme }) => theme.colors.birth};
  }
  &.seed {
    color: ${({ theme }) => theme.colors.seed};
  }
`;

export default BrandListItem;
