import * as React from 'react';
import { graphql } from 'gatsby';
import Modal from 'react-modal';
import { nonNullableFilter, useIsMobile } from 'src/utils';
import {
  ContentColumn,
  PageHeader,
  ModalContent,
  MenuButton,
} from 'src/components/common';
import { BrandList } from './brand-list';
import { Brand } from './brand';
import { ProcessContentFragment } from 'graphql-types';

type Props = ProcessContentFragment & {
  className?: string;
};

export const fragment = graphql`
  fragment ProcessContent on PRISMIC {
    allBrands(lang: $lang, sortBy: date_DESC) {
      edges {
        node {
          ...ProcessBrandListItemWithId
          ...ProcessBrand
          process_main_video_id
        }
      }
    }
  }
`;

function useBrands(allBrands: ProcessContentFragment['allBrands']) {
  if (!allBrands.edges) {
    return [];
  } else {
    return allBrands.edges
      .filter(nonNullableFilter)
      .filter((edge) => edge.node.process_main_video_id)
      .map((edge) => edge.node);
  }
}

type UseCurrentProcessIndexProps = {
  isMobile: boolean;
};
const useCurrentProcessIndex = ({ isMobile }: UseCurrentProcessIndexProps) => {
  const [currentProcessIndex, setCurrentProcessIndex] = React.useState(
    isMobile ? -1 : 0
  );
  const handleClickProcess = React.useCallback((index: number) => {
    setCurrentProcessIndex(index);
  }, []);

  const handleClickClose = React.useCallback<
    React.MouseEventHandler<HTMLButtonElement>
  >(() => {
    setCurrentProcessIndex(-1);
  }, []);

  return { currentProcessIndex, handleClickProcess, handleClickClose };
};

export const Content: React.FC<Props> = ({ className, allBrands }: Props) => {
  const brandSingleId = 'brand-single';
  const isMobile = useIsMobile();
  const brands = useBrands(allBrands);
  const {
    currentProcessIndex,
    handleClickProcess,
    handleClickClose,
  } = useCurrentProcessIndex({ isMobile });
  const title = 'Process';
  return (
    <div className={className || ''}>
      <ContentColumn
        items={[
          {
            component: <PageHeader title={title} isSingle={false} />,
            order: 1,
          },
          {
            component:
              brands.length > 0 ? (
                <BrandList
                  list={brands}
                  onClick={handleClickProcess}
                  targetId={brandSingleId}
                />
              ) : null,
            order: 2,
            hasMarginTop: false,
            hasMarginBottom: false,
          },
          {
            component:
              currentProcessIndex !== -1 ? (
                isMobile ? (
                  <Modal
                    isOpen={currentProcessIndex !== -1}
                    onRequestClose={handleClickClose}
                    style={{
                      overlay: {
                        zIndex: 10,
                      },
                      content: {
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        border: 'none',
                        borderRadius: 'none',
                        padding: 0,
                      },
                    }}
                    contentLabel="Store images"
                  >
                    <ModalContent
                      mobileTitle={title}
                      closeButton={
                        <MenuButton
                          onClick={handleClickClose}
                          isPressed={true}
                          ariaLabel="Close button"
                          targetId={brandSingleId}
                        />
                      }
                    >
                      <Brand
                        {...brands[currentProcessIndex]}
                        id={brandSingleId}
                      />
                    </ModalContent>
                  </Modal>
                ) : (
                  <Brand {...brands[currentProcessIndex]} id={brandSingleId} />
                )
              ) : null,
            order: 3,
            hasMarginTop: false,
          },
        ]}
      />
    </div>
  );
};

export default Content;
