import * as React from 'react';
import { graphql } from 'gatsby';
import { BrandListItem } from './brand-list-item';
import { ProcessBrandListItemWithIdFragment } from 'graphql-types';

type Props = {
  className?: string;
  list: ProcessBrandListItemWithIdFragment[];
  onClick: (index: number) => void;
  targetId: string;
};

export const fragment = graphql`
  fragment ProcessBrandListItemWithId on PRISMIC_Brand {
    _meta {
      uid
    }
    ...ProcessBrandListItem
  }
`;

export const BrandList: React.FC<Props> = ({
  className,
  list,
  onClick,
  targetId,
}: Props) => (
  <div className={className || ''}>
    {list.map((item, index) => (
      <BrandListItem
        key={`brand-list-item-${item?._meta?.uid ?? index}`}
        index={index}
        onClick={onClick}
        targetId={targetId}
        {...item}
      />
    ))}
  </div>
);

export default BrandList;
